const th = {
  network: {
    tips: `เครือข่ายที่เลือกไม่ได้รับการสนับสนุนในปัจจุบัน!
      กรุณาเลือกเครือข่าย Matic (Polygon)!`,
    ev: `โปรดตรวจสอบว่าคุณได้ติดตั้งซอฟต์แวร์กระเป๋าเงินอย่าง MetaMask, 
    Trust Wallet, imToken, หรือ TokenPocket หรือไม่`,
    copyight: process.env.VUE_APP_TITLE_NAME,
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `<h3><strong style="color:yellow">DAPP นี้ไม่ได้รับการคุ้มครองตามกฎหมายในบางประเทศและภูมิภาค โปรดใช้งานด้วยความระมัดระวัง!</strong></h3>
    <br/>
    <p><strong>เรียน ผู้ใช้ทุกท่าน:</strong></p>
<br/>
    <p>ขอขอบคุณที่ให้ความสนใจและสนับสนุน DAPP ของเรา เพื่อปกป้องสิทธิ์ตามกฎหมายของคุณและทำความเข้าใจเกี่ยวกับความเสี่ยงทางกฎหมายที่เกี่ยวข้อง เราขอประกาศข้อสำคัญดังต่อไปนี้:</p><br/>

    <p>DAPP นี้เกี่ยวข้องกับการลงทุนและการทำธุรกรรมในสกุลเงินดิจิทัล ซึ่งในบางประเทศและภูมิภาคอาจขัดต่อกฎหมายท้องถิ่น ตามกฎหมายและนโยบายของหน่วยงานกำกับดูแลในพื้นที่ DAPP นี้ไม่สามารถดำเนินการได้อย่างถูกกฎหมายในบางประเทศและภูมิภาค ดังนั้นเราขอประกาศดังนี้:</p>
<br/>
    <h3><strong>1. ข้อจำกัดในการให้บริการ:</strong></h3><br/>
    <p style="color:yellow">DAPP นี้ไม่ให้บริการการลงทุนและการทำธุรกรรมในสกุลเงินดิจิทัล และไม่ให้การสนับสนุนทางเทคนิคหรือบริการหลังการขายในประเทศและภูมิภาคต่อไปนี้:</p><br/>
    <p style="color:yellow">จีน (รวมถึงจีนแผ่นดินใหญ่ เขตบริหารพิเศษฮ่องกง เขตบริหารพิเศษมาเก๊า และไต้หวัน), อินเดีย, แอลจีเรีย, บังกลาเทศ, โมร็อกโก, อียิปต์, เนปาล, ปากีสถาน, กาตาร์, อิรัก, ตูนิเซีย, อัฟกานิสถาน, โบลิเวีย</p>
   <br/> <strong>เราขอแนะนำให้ผู้ใช้ในประเทศและภูมิภาคที่กล่าวมาข้างต้นหยุดใช้ DAPP นี้ทันทีเพื่อหลีกเลี่ยงความเสี่ยงทางกฎหมายที่อาจเกิดขึ้น</strong>
<br/><br/>
    <h3><strong>2. คำเตือนเกี่ยวกับความเสี่ยงทางกฎหมายและการลงทุน:</strong></h3><br/>
    <p>การใช้ DAPP นี้เพื่อการลงทุนและการทำธุรกรรมในสกุลเงินดิจิทัลในประเทศและภูมิภาคที่กล่าวมาข้างต้นอาจส่งผลให้เกิดผลทางกฎหมายที่ร้ายแรง ทีมงานของเราไม่รับผิดชอบทางกฎหมายใด ๆ ที่เกิดจากการกระทำดังกล่าว นอกจากนี้ ตลาดสกุลเงินดิจิทัลมีความผันผวนสูงและไม่สามารถคาดเดาได้ การลงทุนมีความเสี่ยง โปรดตรวจสอบให้แน่ใจว่าคุณเข้าใจและรับทราบความเสี่ยงที่เกี่ยวข้องอย่างครบถ้วนก่อนตัดสินใจว่าจะใช้ DAPP นี้ต่อไปหรือไม่</p>
<br/>
    <h3><strong>3. การยืนยันของผู้ใช้:</strong></h3><br/>
    <p>หากคุณใช้ DAPP นี้ต่อไปเพื่อการลงทุนและการทำธุรกรรมในสกุลเงินดิจิทัล ถือว่าคุณได้อ่านและเข้าใจเนื้อหาของประกาศนี้โดยละเอียดแล้ว และคุณยอมรับความเสี่ยงทางกฎหมายและการลงทุนที่อาจเกิดขึ้น การดำเนินการใด ๆ ของคุณถือว่าเป็นการยอมรับความรับผิดชอบที่เกี่ยวข้องโดยสมัครใจ และทีมงานไม่รับผิดชอบต่อผลที่ตามมาที่อาจเกิดขึ้น</p>
<br/>
    <h3><strong>4. คำแถลงจากทีมงาน:</strong></h3><br/>
    <p>DAPP นี้ได้รับการพัฒนาและดำเนินการโดยทีมงาน Bull Developer เราปฏิบัติตามหลักการการปฏิบัติตามกฎระเบียบมาโดยตลอด และมุ่งมั่นที่จะให้บริการที่มีคุณภาพสูงแก่ผู้ใช้ อย่างไรก็ตาม เนื่องจากสภาพแวดล้อมทางกฎหมายที่เฉพาะเจาะจงในบางประเทศและภูมิภาค เราไม่สามารถให้บริการที่เกี่ยวข้องกับสกุลเงินดิจิทัลในพื้นที่เหล่านี้ต่อไปได้ เราขอแนะนำอย่างยิ่งให้ผู้ใช้ทุกคนศึกษากฎหมายและข้อบังคับของประเทศหรือภูมิภาคของตนก่อนใช้ DAPP นี้เพื่อหลีกเลี่ยงความเสี่ยงทางกฎหมายและการลงทุนที่อาจเกิดขึ้น</p>
<br/>
    <p><strong>ขอขอบคุณอีกครั้งสำหรับความเข้าใจและการสนับสนุนของคุณ หากคุณมีคำถามหรือจำเป็นต้องได้รับความช่วยเหลือเพิ่มเติม โปรดติดต่อเราผ่านเว็บไซต์อย่างเป็นทางการของเรา</strong></p>
<br/>
    <p><strong>ขอแสดงความนับถือ!</strong></p>
<br/>
    <p><strong>ทีมงาน Bull Developer</strong></p>`,
    protocol: `แอปพลิเคชันที่กระจายอำนาจ (DApp) นี้มีความเสี่ยงในการลงทุนที่ไม่แน่นอน ก่อนใช้งาน โปรดอ่านและทำความเข้าใจในคำเตือนเกี่ยวกับความเสี่ยงและนโยบายความเป็นส่วนตัวอย่างรอบคอบ`,
    more: "รายละเอียด",
    media: "โซเชียลมีเดียที่เกี่ยวข้อง",
    stake:
      "เปิดทั่วโลกในวันที่ 6 พฤศจิกายน 2023 ติดตามเราทาง Telegram และ Twitter!",
    withdraw:
      "เปิดทั่วโลกในวันที่ 6 พฤศจิกายน 2023 ติดตามเราทาง Telegram และ Twitter!",
    Reinvest:
      "เปิดทั่วโลกในวันที่ 6 พฤศจิกายน 2023 ติดตามเราทาง Telegram และ Twitter!",
    Getreward:
      "เปิดทั่วโลกในวันที่ 6 พฤศจิกายน 2023 ติดตามเราทาง Telegram และ Twitter!",
    linkwallet: "เชื่อมต่อกระเป๋าเงิน",
    notification: "การทดสอบสาธารณะของ Falcon Burn Acceleration ได้สิ้นสุดลงอย่างสมบูรณ์แล้ว ต่อไปเราจะทำการปรับใช้สัญญาอย่างเป็นทางการ กรุณารอติดตาม!",
    flashloansOK:'เริ่มต้นสำเร็จ!',
    flashloansNo:'เริ่มต้นไม่สำเร็จ',
    flashloansOnce:'ขอแสดงความยินดีที่คุณได้รับโอกาสทดลอง 1 ครั้ง',
    flashloansError:'ขออภัย, โอกาสในการทดลองได้ถูกใช้ไปแล้ว',
    falocnerror:'Falcon™ V2 ไม่ได้รับอนุญาตให้ทดสอบ!',
    falocninfo:'Falcon™ V2 สำหรับผู้เล่นที่ได้รับการอนุมัติระดับ V3! ขณะนี้อยู่ในช่วงทดสอบสาธารณะ!',
    falconv4:'คลัสเตอร์เก็งกำไร Falcon™V4 กำลังอยู่ในช่วงทดสอบเบต้า (28000 ครั้ง/วัน)!'
  },
  leavel: {
    0:"Dinosaur egg",
    1:"Spinosaurus",
    2:"Stegosaurus",
    3:"Pterosaur",
    4:"Triceratops",
    5:"Brachiosaurus",
    6:"Velociraptor",
    7:"Tyrannosaurus rex"
  },

  top: {
    Account: "บัญชี",
  },
  swap:{
    Swap:"แลกเปลี่ยน",
    paynameA:"การชำระเงิน",
    paynameB:"การรับเงิน",
    Approve:"การอนุญาต",
    launchSwap:"เริ่มการแลกเปลี่ยน",
    recommend:"การแนะนำ",
    swapError:"ข้อผิดพลาดในการแลกเปลี่ยน",
    swapNetwork:"ข้อผิดพลาดของเครือข่าย",
  },
  stake: {
    Balance: "ยอดคงเหลือของคุณ",
    Total: "เดิมพันทั้งหมด",
    Appoove: "อนุมัติ",
    Stake: "เดิมพัน",
    Appooveing: "กำลังอนุมัติ",
    AuthorSuccessful: "อนุมัติสำเร็จ",
    AuthorFailed: "อนุมัติไม่สำเร็จ",
    Stakeing: "กำลังฝาก",
    StakeSuccessful: "ฝากสำเร็จ",
    StakeFailed: "ฝากไม่สำเร็จ",
    StakeInfo: "ไม่สามารถน้อยกว่า:",
    Activeaccount:"เปิดใช้งานบัญชี",
    active:"ปิดใช้งาน​"
  },
  menu: {
    Home: "หน้าแรก",
    Language: "ภาษา",
    Document: "หนังสือขาว",
    Falcon:"Falcon™",
    Contract: "สัญญา",
    Watch: "ดู",
    Coming: "เร็ว ๆ นี้",
    EvDao: "BULLDAO",
    Flashloan: "เปิดตัว Flashloan",
    Developer:"เอกสารนักพัฒนา",
    Launch:"Launch",
    xNumber:"ที่เหลือ",
    xTips:"การประมวลผลอัตโนมัติ",
    LogiV2:"Falcon™ V2",
    LogiV3:"Falcon™ V3",
    LogiV4:"Falcon™ V4",
    Market:"Market",
    LogiV3tips:"Falcon™ V3 เริ่มการทดสอบสาธารณะทั่วโลกแล้ว (คำขอต่อวัน 1000~7000 ครั้ง) ต้องได้รับอนุญาตสำหรับสัญญาอาร์บิทราจที่สอดคล้องกัน!",
    Appoove2:"Auto",
        Ido:"Falcon IDO",
    myIdo:"My Falcon",
    interest:"กำไร",
  },
  ido:{
    select: "Select Community",
    flashmonkey: "FlashMonkey",
    flashdragon: "FlashDrgon",
    fashFalcon: "Falcon",
    powermonger: "PowerMonger",
    fashfalcon2: "FalconV2",
    other: "Other",
    Getmax: "Get Maximum",
    nowbuy: "Subscribe Now",
    mynumber: "My Falcon",
    myrelease: "Released",
    waitrelease: "Waiting for Release",
    get: "Withdraw",
    FalconF: "FlashFalcon(F)",
    FalconA: "FlashFalcon(A)",
    organization: "Organization",
    endTimes:'First Round Countdown',
    getmax:'Get Maximum',
    Authorization:"Authorization",
    Subscribe:"Subscribe Now",
    okSubscribe:"Subscribed",
    message:"Node Error, Please Change Node",
    end1:"First Round Ended",
    needStart:"Countdown to Start",
    getscusse:"Retrieval Successful",
    geterror:"Retrieval Failed"

  },
  Withdraw: {
    Withdraw: "ถอน",
    Withdrawable: "สามารถถอนได้",
    Earnings: "รายได้",
    Brokerage: "จำนวนค่านายหน้า",
    Reinvest: "ลงทุนใหม่",
    Getreward: "รับรางวัล",
    Processing: "กำลังดำเนินการ",
    unsuccessfule: "ไม่ประสบความสำเร็จ",
    successful: "ประสบความสำเร็จ!",
    WithdrawInfo1: "โปรดใส่จำนวนที่ต้องการถอน",
    WithdrawInfo2: "จำนวนไม่เพียงพอ",
    burning:"FalconBurn",
    noBurn: "ยังไม่ได้เร่ง",
    addBurn: "ผลตอบแทนเพิ่มเติม",
    addBurnCost: "ค่าใช้จ่ายเพิ่มเติม",
    BurnCount: "บัญชีการเผา",
    BurnSpeed: "การเร่งการเผา",
    needpay: "จำเป็นต้องชำระเงิน",
    startBurn: "เริ่มการเร่ง",
    Burning: "กำลังเร่ง",
    noBurn1:"Paused",
    cangetfalcon:"คาดว่าจะถอนได้",
    stakedassets:"ทรัพย์สินที่ค้ำประกัน",
    updateInformation:"Update Information"
  },
  Team: {
    innver: "Innver",
    Teamstaked: "ทีมเดิมพัน",
    leavel: "ระดับ",
    Team: "ทีม",
    Bind: "ผูกความสัมพันธ์",
    info: "ระบบพักชั่วคราวการผูกข้อมูล โปรดรอให้เปิด！",
    Binding: "กำลังผูกมัด",
    BindingSuccessful: "ผูกมัดสำเร็จ",
    BindingFailed: "ผูกมัดไม่สำเร็จ",
    Bindinfo: "กรุณาใส่ที่อยู่กระเป๋าเงินของผู้เชิญ",
  },
  Share: {
    Invitelink: "ลิงก์เชิญ",
    Intips:
      "คำเชิญ Dapp ไม่สามารถใช้ได้ในปัจจุบัน โปรดรอการอัปเดตล่าสุดจากชุมชน",
    copyTitle: "ลิงค์เชิญ",
    copyText: "คัดลอกลิงค์เชิญสำเร็จแล้ว!",
    copyCommnad: "คัดลอกลิงค์",
  },
  Contract: {
    contractaddress: "ที่อยู่สัญญา",
    Copylink: "Polygonscan",
    CopylinkText: "ไปที่ polygonscan!",
  },
  Wathch: {
    watchonlywallet: "กระเป๋าสตางค์ดูเท่านั้น",
    addwallet: "เพิ่มกระเป๋าสตางค์ดูเท่านั้น",
    add: "เพิ่ม",
    Leavel: "ระดับ",
    share: "แบ่งปัน",
    fnOpen: "ยังไม่ได้เชิญกระเป๋าเงินนี้เข้าร่วมฟีเจอร์นี้!",
    nullString: "กรุณาใส่ที่อยู่กระเป๋าเงิน！",
    addok:"เพิ่มสำเร็จ",
    addError:"เพิ่มไม่สำเร็จ",
    addisExists:"ที่อยู่นี้มีอยู่แล้ว",
    addlist:"รายการที่ติดตาม",
  },
};
export default th;
